import React from 'react';
import { Link } from 'gatsby';
import { Layout, Button, Col } from 'antd';
import Responsive from '../utils/Responsive';
import styles from '../styles/components.module.css';
import tagbotIcon from '../assets/tagbot_icon.png';

const Row = Responsive.ResponsiveRow;
const { Content } = Layout;
export default function Nav() {
  return (
    <Content className={styles.footer}>
      <Row className={styles.footerRow}>
        <Col span={8} className={styles.footerImage}>
          <Link to="/">
            <img
              className={styles.imgTagboticon}
              src={tagbotIcon}
              alt={'TagBot'}
            />
          </Link>
        </Col>
        <Col span={4}>
          <h3>
            <Link to="/">TagBot</Link>
          </h3>
          <ul style={{ listStyleType: 'none' }}>
            {
              <li>
                <Link data-ci="nav-link-basics" to="/tagging-basics/">
                  Tagging Basics
                </Link>
              </li>
            }
            {
              <li>
                <Link data-ci="nav-link-cases" to="/tag-use-cases/">
                  Tagging Use Cases
                </Link>
              </li>
            }
            {
              <li>
                <Link data-ci="nav-link-practices" to="/tag-best-practices/">
                  Tagging Best Practices
                </Link>
              </li>
            }
            {
              <li>
                <Link data-ci="nav-link-types" to="/tag-types/">
                  Tagging Types
                </Link>
              </li>
            }
            {
              <li>
                <a href="https://trackit.io/trackit-whitepapers/how-trackit-uses-tagbot-internally/">
                  How TrackIt Uses Tagbot
                </a>
              </li>
            }
          </ul>
        </Col>
        {/*
        <Col span={4}>
          <h3>
            <Link to="/features/">Features</Link>
          </h3>
          <ul>
            <li>
              <Link to="/features/#feature1">Feature 1</Link>
            </li>
            <li>
              <Link to="/features/#feature2">Feature 2</Link>
            </li>
            <li>
              <Link to="/features/#feature3">Feature 3</Link>
            </li>
            <li>
              <Link to="/features/#feature4">Feature 4</Link>
            </li>
          </ul>
        </Col>
*/}
        <Col span={4}>
          <h3>More</h3>
          <ul style={{ listStyleType: 'none' }}>
            <li>
              <a
                data-ci="nav-mail-to-contact-us"
                href="mailto:support@tagbot.cloud"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Row style={{ marginBottom: 15, display: 'block' }}>
            <Button
              id="nav-button-register"
              className={styles.buttonFullwidth}
              data-ci="nav-button-register"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = 'https://app.tagbot.cloud/register';
              }}
            >
              Register
            </Button>
          </Row>
          <Row style={{ display: 'block' }}>
            <Button
              id="nav-button-log-in"
              className={styles.buttonInverted}
              data-ci="nav-button-log-in"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = 'https://app.tagbot.cloud/login';
              }}
            >
              Log in
            </Button>
          </Row>
        </Col>
      </Row>
    </Content>
  );
}
