import { Row } from 'antd';
import styled from 'styled-components';

const ResponsiveRow = styled(Row)`
  max-width: 1200px;
  margin: auto;
`;

export default {
  ResponsiveRow,
};
