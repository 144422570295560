import React from 'react';
import { Layout, Button, Card } from 'antd';
import styles from '../styles/components.module.css';
import screenshotMain2 from '../assets/screenshot_main2.png';

const { Content } = Layout;
export default function Cta() {
  return (
    <Content className={styles.cta}>
      <div style={{ width: '100%' }}>
        <Card className={styles.ctas}>
          <h2 className={styles.ctasTitle}>
            Only <span>$19.99 Per Month</span>
          </h2>

          <Button
            className={styles.button2}
            size="large"
            data-ci="cta-button-sign-up-now"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = 'https://app.tagbot.cloud/register';
            }}
          >
            Start Your 2 Week Free Trial Today!
            <br />
            No Credit Card Required
          </Button>
          <img
            className={styles.imgPresentation}
            src={screenshotMain2}
            alt={'Tagging resources Dashboard AWS'}
          />
        </Card>
      </div>
    </Content>
  );
}
