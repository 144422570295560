import React from 'react';
import { Link } from 'gatsby';
import { Layout, Button } from 'antd';
import styles from '../styles/header.module.css';
import logo from '../assets/tagbot-logo.png';

const { Header } = Layout;

export default () => {
  return (
    <Header className={styles.header}>
      <Link to="/">
        <img src={logo} alt="logo" />
      </Link>
      <div style={{ float: 'right' }}>
        <Button
          size="large"
          data-ci="header-button-get-a-demo"
          className={styles.button2}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = 'https://app.tagbot.cloud/login';
          }}
        >
          Login
        </Button>
        <Button
          size="large"
          data-ci="header-button-get-a-demo"
          className={styles.button}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = 'https://app.tagbot.cloud/register';
          }}
        >
          Try it now!
        </Button>
      </div>
    </Header>
  );
};
