import React from 'react';
import { Layout, Col } from 'antd';
import Responsive from '../utils/Responsive';
import styles from '../styles/components.module.css';
import HubspotForm from 'react-hubspot-form';

const Row = Responsive.ResponsiveRow;
const { Content } = Layout;

export default function Newsletter() {
  return (
    <Content className={styles.newsletter}>
      <Row>
        <Col span={24} lg={12}>
          <h3 className={styles.newsletterText}>Subscribe to the newsletter</h3>
        </Col>
        <Col span={24} lg={12} className={styles.newsletterForm}>
          <HubspotForm
            portalId="6544406"
            formId="762d482e-c462-41c6-acfc-e2e7837ddddf"
            loading={<div>Loading...</div>}
          />
        </Col>
      </Row>
    </Content>
  );
}
